import React from "react"
import Layout from "components/layout"
import usePageData from 'hooks/queryPeoplePage';
import ViewPeoplePage from 'components/ViewPeople'

const PeoplePage = props => {
  const { pageContext } = props;
  const pageData = usePageData();

  return (
    <Layout pageContext={pageContext}>
      <ViewPeoplePage pageData={pageData} />
    </Layout>
  )
}

export default PeoplePage
