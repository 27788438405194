import React from 'react'
import SEO from "components/seo"
import Swiper from "react-id-swiper"
import Heading from "components/Heading"
import Quote from "components/Quote"
import Img, {Photo} from "components/Img"
import HeroImageFull from "components/HeroImageFull"
import VideoWrapper from "components/VideoWrapper"
import Interweave from 'interweave'

const ViewPeople = ({pageData}) => {
  const {
    page,
    heading,
    bioBetty,
    bioShaun,
  } = pageData;
  const {
    missionBlock: bettyMission,
    philanthrophyBlock: bettyPhilanthrophy
  } = bioBetty;
  const {
    missionBlock: shaunMission,
  } = bioShaun;
  const params = {
    containerClass: "swiper-container swiper-caption-container people-swiper",
    slidesPerView: 1,
    spaceBetween: 15,
    loop: false,
    navigation: {
      nextEl: ".swiper-button-next.is-white",
      prevEl: ".swiper-button-prev.is-white",
    },
  }

  return (
    <React.Fragment>
      <SEO title={page} />
      <div className="max-container real-hero wrapper--people--hero">
        <HeroImageFull
          heroTextClassname="mobile--is-center"
          imageName="peopleHeroImage"
          imageProps={{
            imgStyle: {
              objectPosition: "center top",
            },
          }}
        >
          <Heading className="is-heading">{heading}</Heading>
        </HeroImageFull>
      </div>
      <div className="max-container wrapper--people--block-2">
        <div className="max-1048 container container-with-background right">
          <div className="col-image">
            <Img
              name={"peopleImageBetty"}
              isFluid={true}
              alt="Betty Tran -- CEO &amp; Founder"
            />
          </div>
          <div className="col-content is-center">
            <div className="content-body">
              <Heading>
                <Interweave content={bioBetty.title} />
              </Heading>
              <Interweave noWrap content={bioBetty.content} />
            </div>
          </div>
        </div>
      </div>

      <div className="max-container wrapper--people--betty-video">
        <Heading className="use-med align-center">
          <Interweave content={bioBetty.video.title} />
        </Heading>
        <VideoWrapper videoId={bioBetty.video.videoId} />
      </div>

      <div className="max-container wrapper--people--block-1 wrapper--people--quote bg-grey max-quote ignore">
        <div className="container max-1048">
          <div className="col-full">
            <Quote className="body-medium is-center">
              <Interweave noWrap content={bioBetty.testimonial} />
            </Quote>
          </div>
        </div>
      </div>

      <div className="max-container wrapper--people--block-mission">
        <div className="max-1048 container container-with-background left">
          <div className="col-full">
            <Heading className="use-med">
              <Interweave noWrap content={bettyMission.title} />
            </Heading>
            <Interweave noWrap content={bettyMission.content} />
          </div>
        </div>
      </div>

      <div className="max-container ignore wrapper--people--block-offset-betty">
        <div className="max-1048 container container-with-background right">
          <div className="col-image">
            <div className="image-offset full">
              <Img
                isFluid={true}
                name={"peopleBackgroundBlock2"}
                alt="Betty Tran -- CEO &amp; Founder"
              />
            </div>
          </div>
          <div className="col-content">
            <div className="content-body">
              <Quote className="body-text">
                <Interweave noWrap content={bettyMission.testimonial} />
              </Quote>
            </div>
          </div>
        </div>
      </div>

      <div className="max-container wrapper--people--block-5">
        <div className="max-1048 container container-with-background left">
          <div className="col-full">
              <Heading className="use-med">
                <Interweave noWrap content={bettyPhilanthrophy.title} />
              </Heading>
          </div>
          <div className="col-content is-center">
            <div className="content-body">
              <Interweave noWrap content={bettyPhilanthrophy.content} />
            </div>
          </div>
          <div className="col-image">
            <Swiper {...params}>
              {bettyPhilanthrophy.slideshow.map(({image, title}, index) => (
                <div key={`telstra-awards-slide-image-${index}`} className="each-image">
                  <div className="swiper-caption-item">
                    <Photo
                      className="swiper-caption--image"
                      data={image}
                      alt={title}
                    />
                    <div className="swiper-caption--caption">{title}</div>
                  </div>
                </div>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      <div className="max-container ignore wrapper--people--block-6 is-dark-bg">
        <div className="max-1048 container container-with-background right">
          <div className="col-image">
            <Img
              isFluid={true}
              name="peopleImageShaunBio"
              alt={bioShaun.title}
            />
          </div>
          <div className="col-content">
            <div className="content-body">
              <Heading>
                <Interweave noWrap content={bioShaun.title} />
              </Heading>
              <Interweave noWrap content={bioShaun.content} />
            </div>
          </div>

        </div>
      </div>

      <div className="max-container ignore wrapper--people--block-shaun-quote">
        <div className="container max-1048">
          <Quote className="is-white-quote body-medium is-center">
            <Interweave noWrap content={bioShaun.testimonial} />
          </Quote>
        </div>
      </div>

      <div className="max-container ignore is-dark-bg wrapper--people--block-shaun-video">
        <Heading className="use-med align-center">
          <Interweave noWrap content={bioShaun.video.title} />
        </Heading>
        <VideoWrapper videoId={bioShaun.video.videoId} />
      </div>

      <div className="max-container ignore is-dark-bg wrapper--people--block-7">
        <div className="max-1048 container container-with-background left">
          <div className="col-full">
            <Heading className="use-med">
              <Interweave noWrap content={shaunMission.title} />
            </Heading>
          </div>
          <div className="col-image">
            <Img
              isFluid={true}
              name="peopleImageShaunMission"
              alt={`Shaun ${shaunMission.title}`}
            />
          </div>
          <div className="col-content is-center">
            <div className="content-body">
              <Interweave noWrap content={shaunMission.content} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewPeople